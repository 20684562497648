* {
	margin: 0;
	padding: 0;
	border: none;
	outline: none;
	box-sizing: border-box;
}

body {
	width: 100%;
	height: auto;
	min-height: 100vh;
	padding-top: 1rem;
	display: flex;
	flex-direction: column;
	font-family: 'Open Sans', sans-serif;
	font-size: 1rem;
	-webkit-text-size-adjust: 100%;
	font-weight: 300;
	line-height: 1.8;
	color: #505050;
	-ms-overflow-style: none;
	scrollbar-width: none;
}

body::-webkit-scrollbar {
	display: none;
}

@media (max-width: 882px) {
	body {
		padding-top: 0;
	}
}

input, select, textarea, button {
	font-family: inherit;
	font-size: 1rem;
	font-weight: 300;
	line-height: normal;
	color: #505050;
}

::placeholder {
	color: darkgray;
	opacity: 1;
}

:-ms-input-placeholder {
  color: darkgray;
}

::-ms-input-placeholder {
  color: darkgray;
}

.welcome-section {
	width: 794px;
	height: auto;
	margin-top: 0;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
}

@media (max-width: 882px) {
	.welcome-section {
		width: 90%;
	}
}

.welcome-logo-container {
	width: 100%;
	height: auto;
	margin-top: 3rem;
	text-align: center;
}

.welcome-logo-image {
	width: 4rem;
	height: auto;
	user-select: none;
	-webkit-user-select: none;
}

.welcome-header {
	margin-top: 1rem;
	font-size: 2rem;
	font-weight: 700;
	color: #009abf;
	line-height: 1.25;
	text-align: center;
}

.welcome-p {
	margin-top: 1rem;
	font-size: 1.25rem;
	font-weight: 300;
	line-height: 1.25;
	text-align: center;
}

.welcome-text-input {
	width: 100%;
	height: auto;
	margin-top: 1.25rem;
	padding: 0.5rem 0.75rem;
	display: block;
	font-size: 1.25rem;
	border: 1px solid darkgray;
	border-radius: 8px;
	outline: none;
}

.welcome-text-input:focus {
	border-color: #ff6c37;
}

.welcome-text-input:focus::placeholder {
	color: transparent;
}

.welcome-text-input:disabled {
	color: #505050;
	-webkit-text-fill-color: #505050;
	background-color: whitesmoke;
	border: 1px solid transparent;
	opacity: 1;
}

.welcome-button-container {
	margin-top: 1.5rem;
	text-align: center;
}

.welcome-button {
	max-width: 425px;
	width: 100%;
	padding: 0.4rem 1.25rem;
	color: #ff6c37;
	font-size: 1.25rem;
	font-weight: 600;
	background-color: white;
	border-radius: 16px;
	border: 2px solid #ff6c37;
	outline: none;
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
}

@media (hover:hover) {
	.welcome-button:hover {
		color: white;
		background-color: #ff6c37;
	}
}

.welcome-button:active {
	color: white;
	background-color: #ff6c37;
}

.welcome-button:disabled {
	pointer-events: none;
	color: rgba(255, 108, 55, 0.7);
	background-color: rgba(255, 108, 55, 0.05);
	border-color: rgba(255, 108, 55, 0.1);
	transition-property: opacity, transform;
	transition-duration: 0.5s;
}

.actions-section {
	width: 794px;
	margin-top: 1.5rem;
	margin-bottom: 4rem;
	margin-left: auto;
	margin-right: auto;
	opacity: 0;
	transition-property: opacity;
	transition-duration: 0.7s;
}

@media (max-width: 882px) {
	.actions-section {
		width: 90%;
	}
}

.let-us-know {
	font-size: 1.5rem;
	font-weight: 600;
	line-height: 1.25;
	text-align: center;
	margin-bottom: 1rem;
	width: 100%;
	margin-left: auto;
	margin-right: auto;
}

.actions-container {
	display: grid;
	grid-template-columns: auto min-content;
	max-width: 425px;
	max-height: 375px;
	margin: auto;
	overflow: hidden scroll;
	border-radius: 16px;
}

.actions-container .actions-item-title-and-author:nth-child(4n+1),
.actions-container .actions-item-checkboxes:nth-child(4n+2) {
    background-color: rgba(0,154,191,.04);
}

.actions-container .actions-item-title-and-author:nth-child(4n+3),
.actions-container .actions-item-checkboxes:nth-child(4n+4) {
    background-color: rgba(255,108,55,.05);
}

.actions-item-title-and-author {
	display: flex;
	flex-direction: column;
	padding-left: 20px;
	padding-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
}

@media (max-width: 600px) {
	.actions-item-title-and-author {
		padding-left: 15px;
		padding-right: 10px;
	}
}

.actions-item-title {
	font-weight: 700;
}

.actions-item-checkboxes {
	display: flex;
	flex-direction: column;
	padding-right: 20px;
	padding-top: 12px;
	padding-bottom: 12px;
}

@media (max-width: 600px) {
	.actions-item-checkboxes {
		padding-right: 15px;
	}
}

.actions-item-checkbox-and-label {
	display: flex;
	align-items: center;
}

.actions-item-checkbox-label {
	padding-left: 3px;
}

.actions-button {
	display: block;
	margin: 1rem auto;
	max-width: 425px;
	width: 100%;
	padding: 0.4rem;
	color: #505050;
	font-size: 1.25rem;
	font-weight: 600;
	background-color: white;
	border-radius: 16px;
	border: 2px solid #505050;
	outline: none;
	cursor: pointer;
	user-select: none;
}

@media (hover:hover) {
	.actions-button:hover {
		color: white;
		background-color: #505050;
	}
}

.actions-button:active {
	color: white;
	background-color: #505050;
}

.actions-button:disabled {
	pointer-events: none;
	color: rgba(0,0,0,0.6);
	background-color: rgba(0,0,0,0.02);
	border-color: rgba(0,0,0,0.1);
}

.join-container {
	width: 450px;
	height: auto;
	margin: auto;
	padding-top: 1.5rem;
	border-top: 1px solid rgba(0, 0, 0, 0.2);
}

@media (max-width: 615px) {
	.join-container {
		width: 70%;
	}
}

@media (max-width: 500px) {
	.join-container {
		width: 90%;
	}
}

.join-cta {
	font-size: 1rem;
	font-weight: 300;
	line-height: 1;
	text-align: center;
}

.join-form-container {
	display: flex;
	margin-top: 1rem;
	margin-bottom: 2.5rem;
}

.join-text-input {
	width: 75%;
	height: 34px;
	padding: 0.5rem 0.75rem;
	margin-right: 0.5rem;
	font-size: 0.75rem;
	display: block;
	border: 1px solid darkgray;
	border-radius: 8px;
	outline: none;
}

.join-text-input:focus {
	border-color: #ff6c37;
}

.join-text-input:focus::placeholder {
	color: transparent;
}

.join-text-input:disabled {
	color: #505050;
	-webkit-text-fill-color: #505050;
	background-color: whitesmoke;
	border: 1px solid transparent;
	opacity: 1;
}

.join-button {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 25%;
	height: 34px;
	padding: 0.4rem;
	margin-left: 0.5rem;
	color: #ff6c37;
	font-size: 0.75rem;
	font-weight: 700;
	background-color: white;
	border-radius: 8px;
	border: 2px solid #ff6c37;
	outline: none;
	cursor: pointer;
	user-select: none;
	-webkit-user-select: none;
}

@media (hover:hover) {
	.join-button:hover {
		color: white;
		background-color: #ff6c37;
	}
}

.join-button:active {
	color: white;
	background-color: #ff6c37;
}

.join-button:disabled {
	color: white;
	background-color: #ff6c37;
}

.join-checkmark {
	transform: translate(-50%, 50%) scale(0);
	opacity: 0;
	transition-property: transform, opacity;
	transition-timing-function: ease-in-out;
	transition-duration: 0.25s;
}
